import axios from "axios";
import { ROOT_URL } from "./rootConfigs";
import { notification } from "antd";
import { history } from "../history";

// Create an Axios instance
const mikeAxios = axios.create({
  baseURL: ROOT_URL, // Replace with your API's base URL
});

// Add a request interceptor
mikeAxios.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token"); // Replace with your token retrieval logic
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error); // Propagate the error so it can be caught by the response interceptor
  },
);
mikeAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const status = error?.response?.status || 0;
    const errorMessage =
      error?.response?.data?.message ||
      "Something went wrong. Please try again.";

    const notifyError = (description) => {
      notification.error({
        message: "Error",
        description,
      });
    };
    switch (status) {
      case 400:
        return Promise.reject(error);
      // notifyError("Oops! There was a problem with your request. Please check and try again.");
      // break;
      case 401:
        localStorage.clear();
        sessionStorage.clear();
        history.push("/");
        window.location.reload();
        notifyError("You need to log in to continue.");
        break;
      case 403:
        notifyError(
          "Sorry, you don’t have permission to access this resource.",
        );
        break;
      case 404:
        return Promise.reject(error);
      // notifyError("We couldn’t find what you’re looking for.");
      // break;
      case 405:
        notifyError("The action you're trying to perform is not allowed.");
        break;
      case 406:
        notifyError(
          "The server couldn’t provide the requested content in the format you need.",
        );
        break;
      case 408:
        notifyError("The server took too long to respond. Please try again.");
        break;
      case 409:
        notifyError("There’s a conflict with your request. Please try again.");
        break;
      case 410:
        notifyError("The resource you’re looking for is no longer available.");
        break;
      case 411:
        notifyError(
          "The request is missing some important details. Please check and try again.",
        );
        break;
      case 413:
        notifyError(
          "The information you provided is too large. Please reduce it and try again.",
        );
        break;
      case 414:
        notifyError(
          "The link you’re using is too long. Please try again with a shorter one.",
        );
        break;
      case 415:
        notifyError("The file format or data type is not supported.");
        break;
      case 417:
        notifyError(
          "The server couldn’t process your request. Please try again.",
        );
        break;
      case 429:
        notifyError(
          "You’re making requests too quickly. Please slow down and try again later.",
        );
        break;
      case 500:
        notifyError("Something went wrong on our end. Please try again later.");
        break;
      case 501:
        notifyError(
          "This feature is not available yet. Please try again later.",
        );
        break;
      case 502:
        notifyError(
          "There was a problem connecting to the server. Please try again.",
        );
        break;
      case 503:
        notifyError(
          "The service is temporarily unavailable. Please try again later.",
        );
        break;
      case 504:
        notifyError("The server took too long to respond. Please try again.");
        break;
      case 505:
        notifyError("The server doesn’t support the version of the request.");
        break;
      case 507:
        notifyError(
          "There is not enough storage to process the request. Please try again later.",
        );
        break;
      case 508:
        notifyError(
          "The server encountered an issue while processing your request. Please try again.",
        );
        break;
      case 510:
        notifyError(
          "An additional feature is required to complete this request. Please contact support.",
        );
        break;
      default:
        notifyError(errorMessage);
        console.error("Unhandled error:", error);
        break;
    }

    return Promise.reject(error);
  },
);

export default mikeAxios;
