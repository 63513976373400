import React, { useState, useEffect } from "react";
import { Drawer, Row, Col, Input, Radio, Button, Checkbox, Alert } from "antd";

const CheckboxGroup = Checkbox.Group;

const CreateReport = (props) => {
  const {
    open,
    setOpen,
    report_columns = [],
    showPDF = false,
    handleCreateReport,
  } = props;

  const [reportName, setReportName] = useState("");
  const [reportFormat, setReportFormat] = useState(showPDF ? "PDF" : "EXCEL");
  const [selectedColumns, setSelectedColumns] = useState([]);

  useEffect(() => {
    setSelectedColumns(report_columns);
  }, [report_columns?.length]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmitReport = () => {
    handleClose();
    let body = {
      report_format: reportFormat,
      report_name: reportName,
      report_columns: reportFormat === "EXCEL" ? [...selectedColumns] : [],
    };
    handleCreateReport?.(body);
  };

  return (
    <Drawer
      title={<h3>Custom Report Details</h3>}
      placement="right"
      size={"large"}
      closable={false}
      open={open}
      onClose={() => handleClose()}
      footer={
        <>
          <Button type="primary" ghost onClick={() => handleSubmitReport()}>
            Confirm
          </Button>
          &emsp;
          <Button type="primary" danger ghost onClick={() => handleClose()}>
            Cancel
          </Button>
        </>
      }
    >
      <Row>
        <Col span={16}>
          <p className="fw_500">Enter the name of your report:</p>
          <Input
            onChange={(event) => {
              if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                setReportName(event.target.value);
              }
            }}
            value={reportName}
            placeholder="Report's name (Optional)"
          />
        </Col>
      </Row>
      <Row className="m-top-20">
        <Col span={24}>
          <p className="fw_500">Choose your preferred report format:</p>
          <Radio.Group
            value={reportFormat}
            onChange={(event) => setReportFormat(event.target.value)}
            size="small"
          >
            {showPDF && (
              <Radio value={"PDF"} style={{ fontWeight: 400 }}>
                PDF
              </Radio>
            )}
            <Radio value={"EXCEL"} style={{ fontWeight: 400 }}>
              EXCEL
            </Radio>
          </Radio.Group>
        </Col>
      </Row>
      {reportFormat === "EXCEL" && (
        <Row className="m-top-20">
          <p className="fw_500">
            Choose your preferred Tables for your Selected Data:
          </p>
          <Col span={24}>
            <Alert
              message="INFO: By default the report will contain all columns."
              type="warning"
            />
          </Col>
          <Col span={24} className="m-top-20">
            <p className="fw_600">Columns</p>
            <CheckboxGroup
              style={{
                display: "flex",
                flexDirection: "column",
              }}
              className="checkbox-custom-dropdown"
              onChange={(checkedValues) => setSelectedColumns(checkedValues)}
              value={selectedColumns}
              options={report_columns}
            />
          </Col>
        </Row>
      )}
    </Drawer>
  );
};

export default CreateReport;
