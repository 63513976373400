export const GET_MANAGE_UNPUBLISHED_PATENT_LIST =
  "GET_MANAGE_UNPUBLISHED_PATENT_LIST";
export const ADD_UNPUBLISHED_PATENT = "ADD_UNPUBLISHED_PATENT";
export const BULK_ADD_UNPUBLISHED_PATENT = "BULK_ADD_UNPUBLISHED_PATENT";
export const UPDATE_UNPUBLISHED_PATENT = "UPDATE_UNPUBLISHED_PATENT";
export const DELETE_UNPUBLISHED_PATENT = "DELETE_UNPUBLISHED_PATENT";
export const GET_SINGLE_UNPUBLISHED_PATENT = "GET_SINGLE_UNPUBLISHED_PATENT";

export const UNPUBLISHED_PATENT_application_types_FILTER =
  "UNPUBLISHED_PATENT_application_types_FILTER";
export const UNPUBLISHED_PATENT_inventors_FILTER =
  "UNPUBLISHED_PATENT_inventors_FILTER";
export const UNPUBLISHED_PATENT_applicants_FILTER =
  "UNPUBLISHED_PATENT_applicants_FILTER";
export const UNPUBLISHED_PATENT_application_offices_FILTER =
  "UNPUBLISHED_PATENT_application_offices_FILTER";
export const UNPUBLISHED_PATENT_application_statuses_FILTER =
  "UNPUBLISHED_PATENT_application_statuses_FILTER";

export const UPDATE_UNPUBLISHED_PATENT_FILTER_SELECTED_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_FILTER_SELECTED_VALUE";
export const UPDATE_UNPUBLISHED_PATENT_FILTER_EXCLUDED_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_FILTER_EXCLUDED_VALUE";
export const UPDATE_UNPUBLISHED_PATENT_FILTER_SEARCH_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_FILTER_SEARCH_VALUE";
export const UPDATE_UNPUBLISHED_PATENT_DATE_FILTER_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_DATE_FILTER_VALUE";

export const UPDATE_UNPUBLISED_PATENT_RESET_ALL_FILTERS =
  "UPDATE_UNPUBLISED_PATENT_RESET_ALL_FILTERS";
export const UPDATE_UNPUBLISHED_PATENT_FILTER_APPLIED =
  "UPDATE_UNPUBLISHED_PATENT_FILTER_APPLIED";
export const UPDATE_UNPUBLISHED_PATENT_GLOBAL_SEARCH_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_GLOBAL_SEARCH_VALUE";

export const GET_UNPUBLISHED_PATENT_STATISTICS =
  "GET_UNPUBLISHED_PATENT_STATISTICS";
export const UPDATE_UNPUBLISHED_PATENT_DEADLINES =
  "UPDATE_UNPUBLISHED_PATENT_DEADLINES";

export const GET_DEADLINE_UNPUBLISHED_PATENT_LIST =
  "GET_DEADLINE_UNPUBLISHED_PATENT_LIST";

export const UPDATE_DEADLINE_UNPUBLISHED_PATENT_DEADLINE_TYPE =
  "UPDATE_DEADLINE_UNPUBLISHED_PATENT_DEADLINE_TYPE";

export const UNPUBLISHED_PATENT_DEADLINE_application_types_FILTER =
  "UNPUBLISHED_PATENT_DEADLINE_application_types_FILTER";
export const UNPUBLISHED_PATENT_DEADLINE_inventors_FILTER =
  "UNPUBLISHED_PATENT_DEADLINE_inventors_FILTER";
export const UNPUBLISHED_PATENT_DEADLINE_applicants_FILTER =
  "UNPUBLISHED_PATENT_DEADLINE_applicants_FILTER";
export const UNPUBLISHED_PATENT_DEADLINE_application_offices_FILTER =
  "UNPUBLISHED_PATENT_DEADLINE_application_offices_FILTER";
export const UNPUBLISHED_PATENT_DEADLINE_application_statuses_FILTER =
  "UNPUBLISHED_PATENT_DEADLINE_application_statuses_FILTER";

export const UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_SELECTED_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_SELECTED_VALUE";
export const UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_EXCLUDED_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_EXCLUDED_VALUE";
export const UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_SEARCH_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_SEARCH_VALUE";
export const UPDATE_UNPUBLISHED_PATENT_DEADLINE_DATE_FILTER_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_DEADLINE_DATE_FILTER_VALUE";

export const UPDATE_UNPUBLISED_PATENT_DEADLINE_RESET_ALL_FILTERS =
  "UPDATE_UNPUBLISED_PATENT_DEADLINE_RESET_ALL_FILTERS";
export const UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_APPLIED =
  "UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_APPLIED";
export const UPDATE_UNPUBLISHED_PATENT_DEADLINE_GLOBAL_SEARCH_VALUE =
  "UPDATE_UNPUBLISHED_PATENT_DEADLINE_GLOBAL_SEARCH_VALUE";

export const UPDATE_UNPUBLISHED_PATENT_REPLY_FILED =
  "UPDATE_UNPUBLISHED_PATENT_REPLY_FILED";

export const CREATE_UNPUBLISHED_PATENT_REPORT =
  "CREATE_UNPUBLISHED_PATENT_REPORT";
export const DOWNLOAD_UNPUBLISHED_PATENT_PORTFOLIO =
  "DOWNLOAD_UNPUBLISHED_PATENT_PORTFOLIO";
