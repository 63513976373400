import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import {
  GET_DEADLINE_UNPUBLISHED_PATENT_LIST,
  UPDATE_DEADLINE_UNPUBLISHED_PATENT_DEADLINE_TYPE,
} from "../../../actions/managerActions/unPublishedPatentDashboard/types";

const initialState = {
  data: [],
  report_columns: [],
  metadata: {
    count: 0,
    pageNumber: 1,
  },
  isDataLoaded: false,
  loading: false,
  deadline_type: "FORM_18",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_DEADLINE_UNPUBLISHED_PATENT_LIST: {
      switch (action.status) {
        case SUCCESS:
          return {
            ...state,
            data: action.payload.data,
            isDataLoaded: true,
            loading: false,
            metadata: {
              count: action.payload.metadata.count,
              pageNumber: action.pageNumber,
            },
            report_columns: action?.payload?.metadata?.report_columns ?? [],
          };

        case LOADING:
          return {
            ...state,
            loading: true,
            isDataLoaded: false,
          };

        case ERROR:
          return {
            ...state,
            data: [],
            isDataLoaded: false,
            loading: false,
            metadata: {
              count: 0,
              pageNumber: 1,
            },
          };

        default:
          return state;
      }
    }

    case UPDATE_DEADLINE_UNPUBLISHED_PATENT_DEADLINE_TYPE: {
      return {
        ...state,
        deadline_type: action.value,
      };
    }

    default:
      return state;
  }
}
