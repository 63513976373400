import React from "react";
import CreateReport from "../../../../commonComponents/customReport/CustomReport";
import { useDispatch, useSelector } from "react-redux";
import { createUnPublishedPatentReport } from "../../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";

const CreateReportContainer = (props) => {
  const { type, selected, selectAll, generateFilterObject } = props;
  const { report_columns: manage_report_columns } = useSelector(
    (state) => state.managerState.unPublishedPatent.manage,
  );

  const { report_columns: deadline_report_columns, deadline_type } =
    useSelector((state) => state.managerState.unPublishedPatent.dashboard);

  const dispatch = useDispatch();

  const handleCreateReport = async (callbackBody) => {
    let body = {
      ...callbackBody,
      filter_flag: false,
      selected_ids: selected,
      creation_origin: "UNPUBLISHED_PATENT",
    };

    if (type === "DEADLINE") {
      body.deadline_type = deadline_type;
      body.deadline_dashboard_flag = true;
    }

    if (selectAll) {
      let newFilterObject = generateFilterObject();
      let modifiedBody = {
        ...body,
        filter_flag: true,
        selected_ids: [],
        filters: newFilterObject,
      };
      dispatch(createUnPublishedPatentReport(modifiedBody));
    } else {
      dispatch(createUnPublishedPatentReport(body));
    }
  };

  const propsForCreateReport = {
    ...props,
    report_columns:
      type === "DEADLINE" ? deadline_report_columns : manage_report_columns,
    handleCreateReport,
  };

  return <CreateReport {...propsForCreateReport} />;
};

export default CreateReportContainer;
