import {
  Button,
  Col,
  DatePicker,
  Form,
  Modal,
  Row,
  Spin,
  Typography,
} from "antd";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateUnPublishedPatentReplyFiled } from "../../../../../../redux/actions/managerActions/unPublishedPatentDashboard/deadlinePatent";

const { Text } = Typography;

const AddReplyFiled = (props) => {
  const { open, setOpen, selectedDataForReplyFiled, refetch } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const formInitialValues = {
    // pk: selectedDataForReplyFiled?.deadline?.id ?? "",
    pk: selectedDataForReplyFiled?.id ?? "",
    complied_date: "",
  };

  const formLabel = (title) => {
    return <Text strong>{title}</Text>;
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const handleSubmit = (values) => {
    setLoading(true);
    const body = {
      complied_date: values?.complied_date
        ? dayjs(values.complied_date).format("DD-MM-YYYY")
        : null,
      // pk: selectedDataForReplyFiled?.deadline.id,
      pk: selectedDataForReplyFiled?.id,
      update_deadline: true,
    };
    dispatch(
      updateUnPublishedPatentReplyFiled(body, {
        onSuccess: () => {
          setLoading(false);
          refetch?.();
          onClose?.();
        },
        onError: () => {
          setLoading(false);
        },
      }),
    );
  };

  return (
    <>
      <Modal
        title={"Reply Filed"}
        centered
        open={open}
        onCancel={loading ? null : onClose}
        footer={[
          <Button
            key="cancel"
            disabled={loading}
            type="default"
            onClick={onClose}
          >
            Cancel
          </Button>,
          <Button
            loading={loading}
            key="submit"
            type="primary"
            disabled={loading}
            onClick={() => form.submit()}
          >
            Save
          </Button>,
        ]}
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
        width={500}
        closable={!loading}
      >
        <Form
          form={form}
          id="bulk-upload"
          layout="vertical"
          style={{ margin: "15px 10px" }}
          onFinish={handleSubmit}
          initialValues={formInitialValues}
        >
          <Spin spinning={loading}>
            <Row>
              <Col xs={24}>
                <Form.Item
                  label={formLabel("Complied Date")}
                  name="complied_date"
                  rules={[
                    {
                      required: true,
                      message: "Please select Date.",
                    },
                  ]}
                >
                  <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>
              <Col xs={24}>
                <p style={{ color: "#5a6675" }}>
                  <span style={{ color: "red", fontWeight: "bold" }}>
                    Note:{" "}
                  </span>
                  This action will update the <b>Complied Date</b> and remove
                  the corresponding deadline record from the{" "}
                  <b>Deadline page.</b>
                </p>
              </Col>
            </Row>
          </Spin>
        </Form>
      </Modal>
    </>
  );
};

export default AddReplyFiled;
