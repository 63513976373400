import Axios from "axios";
import {
  ADD_UNPUBLISHED_PATENT,
  BULK_ADD_UNPUBLISHED_PATENT,
  CREATE_UNPUBLISHED_PATENT_REPORT,
  DELETE_UNPUBLISHED_PATENT,
  DOWNLOAD_UNPUBLISHED_PATENT_PORTFOLIO,
  GET_MANAGE_UNPUBLISHED_PATENT_LIST,
  GET_SINGLE_UNPUBLISHED_PATENT,
  GET_UNPUBLISHED_PATENT_STATISTICS,
  UPDATE_UNPUBLISED_PATENT_RESET_ALL_FILTERS,
  UPDATE_UNPUBLISHED_PATENT,
  UPDATE_UNPUBLISHED_PATENT_DATE_FILTER_VALUE,
  UPDATE_UNPUBLISHED_PATENT_DEADLINES,
  UPDATE_UNPUBLISHED_PATENT_FILTER_APPLIED,
  UPDATE_UNPUBLISHED_PATENT_FILTER_EXCLUDED_VALUE,
  UPDATE_UNPUBLISHED_PATENT_FILTER_SEARCH_VALUE,
  UPDATE_UNPUBLISHED_PATENT_FILTER_SELECTED_VALUE,
  UPDATE_UNPUBLISHED_PATENT_GLOBAL_SEARCH_VALUE,
} from "./types";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import { MGR_RT_URL, ROOT_URL } from "../../../../configs/rootConfigs";
import mikeAxios from "./../../../../configs/mikeAxios";
import { notification } from "antd";

export function getManageUnPublishedPatentList(
  pageNumber,
  body,
  callbacksFunction = {},
) {
  return async (dispatch) => {
    const { onSuccess, onError } = callbacksFunction;
    dispatch({ type: GET_MANAGE_UNPUBLISHED_PATENT_LIST, status: LOADING });
    try {
      const data = {
        ...body,
      };

      let response = await mikeAxios.put(
        `${MGR_RT_URL}/unpublised_patents/list_layout`,
        data,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
          params: {
            page: pageNumber,
            page_size: 100,
          },
        },
      );
      onSuccess?.(response?.data);
      dispatch({
        type: GET_MANAGE_UNPUBLISHED_PATENT_LIST,
        status: SUCCESS,
        payload: response?.data,
        pageNumber,
      });
    } catch (error) {
      // onResponseError?.(error);
      onError?.(error?.response?.data);
      dispatch({
        type: GET_MANAGE_UNPUBLISHED_PATENT_LIST,
        status: ERROR,
        payload: error,
        pageNumber,
      });
    }
  };
}

export function addUnPublishedPatent(body, callbacksFunction = {}) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: ADD_UNPUBLISHED_PATENT, status: LOADING });
    try {
      let response = await mikeAxios.post(
        `${MGR_RT_URL}/unpublised_patents/manage`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: "UnPublished Patent Added Successfully!",
      });
      dispatch({ type: ADD_UNPUBLISHED_PATENT, status: SUCCESS });
      onSuccess(response?.data);
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.message ?? "Something went wrong!",
        description:
          error?.response?.data?.payload ??
          "Unfortunately, an error occured while Adding Patent. Please, try again later.",
      });
      // onResponseError(error);
      onError(error?.response?.data);
      dispatch({ type: ADD_UNPUBLISHED_PATENT, status: ERROR });
    }
  };
}

export function bulkAddUnPublishedData(body, callbacksFunction = {}) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: BULK_ADD_UNPUBLISHED_PATENT, status: LOADING });
    try {
      let response = await mikeAxios.post(
        `${MGR_RT_URL}/unpublised_patents/manage`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      dispatch({ type: BULK_ADD_UNPUBLISHED_PATENT, status: SUCCESS });
      onSuccess?.(response?.data);
    } catch (error) {
      // onResponseError?.(error);
      onError(error?.response?.data);
      dispatch({ type: BULK_ADD_UNPUBLISHED_PATENT, status: ERROR });
    }
  };
}

export function updateUnPublishedPatent(body, callbacksFunction = {}) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: UPDATE_UNPUBLISHED_PATENT, status: LOADING });
    try {
      let response = await mikeAxios.put(
        `${MGR_RT_URL}/unpublised_patents/manage`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: "UnPublished Patent Updated Successfully!",
      });
      dispatch({ type: UPDATE_UNPUBLISHED_PATENT, status: SUCCESS });
      onSuccess(response?.data);
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.message ?? "Something went wrong!",
        description:
          error?.response?.data?.payload ??
          "Unfortunately, an error occured while Adding Patent. Please, try again later.",
      });
      dispatch({ type: UPDATE_UNPUBLISHED_PATENT, status: ERROR });
      // onResponseError(error);
      onError(error?.response?.data);
    }
  };
}

export function getManageUnPublishedPatentFilter(body, callbacksFunction = {}) {
  return async (dispatch) => {
    const { onSuccess, onError } = callbacksFunction;
    dispatch({
      type: `UNPUBLISHED_PATENT_${body.filter_key}_FILTER`,
      status: LOADING,
      filter_key: body?.filter_key ?? "",
    });
    try {
      const params = {
        page: body?.page ?? 1,
        page_size: body?.page_size ?? 1000,
        search_term: body?.search_term ?? "",
        filter_type: body?.filter_key ?? "",
      };

      let response = await mikeAxios.get(
        `${MGR_RT_URL}/unpublised_patents/filters`,
        {
          params: params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      onSuccess?.(response?.data);
      dispatch({
        type: `UNPUBLISHED_PATENT_${body.filter_key}_FILTER`,
        status: SUCCESS,
        payload: response?.data,
        page: body?.page ?? 1,
        filter_key: body?.filter_key ?? "",
      });
    } catch (error) {
      // onResponseError?.(error);
      onError?.(error?.response?.data);
      dispatch({
        type: `UNPUBLISHED_PATENT_${body.filter_key}_FILTER`,
        status: ERROR,
        payload: error,
        filter_key: body?.filter_key ?? "",
        page: body?.page ?? 1,
      });
    }
  };
}

export function getSinglePublishedPatentData(patentId, callbacksFunction = {}) {
  return async (dispatch) => {
    const { onSuccess, onError } = callbacksFunction;
    dispatch({ type: GET_SINGLE_UNPUBLISHED_PATENT, status: LOADING });
    try {
      const params = {};

      let response = await mikeAxios.get(
        `${MGR_RT_URL}/refined_unpublished_patent/${patentId}`,
        {
          params: params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      onSuccess?.(response?.data);
      dispatch({
        type: GET_SINGLE_UNPUBLISHED_PATENT,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      // onResponseError?.(error);
      onError?.(error?.response?.data);
      dispatch({
        type: GET_SINGLE_UNPUBLISHED_PATENT,
        status: ERROR,
        payload: error,
      });
    }
  };
}

export function changeUnPublishedPatentFilterSelectedValues(
  filter_key,
  values,
  onlyValues,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_FILTER_SELECTED_VALUE,
      filter_key,
      values,
      onlyValues,
    });
  };
}

export function changeUnPublishedPatentFilterExcludeValues(
  filter_key,
  values,
  onlyValues,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_FILTER_EXCLUDED_VALUE,
      filter_key,
      values,
      onlyValues,
    });
  };
}

export function changeUnPublishedPatentFilterSearchValues(filter_key, value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_FILTER_SEARCH_VALUE,
      filter_key,
      value,
    });
  };
}

export function changeUnPublishedPatentResetAllFilter() {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISED_PATENT_RESET_ALL_FILTERS,
    });
  };
}

export function changeUnPublishedPatentFilterApplied(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_FILTER_APPLIED,
      value,
    });
  };
}

export function changeUnPublishedPatentDateFilterValues(filter_key, values) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_DATE_FILTER_VALUE,
      filter_key,
      values,
    });
  };
}

export function changeUnPublishedPatentGlobalSearchValues(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_GLOBAL_SEARCH_VALUE,
      value,
    });
  };
}

export function getManageUnPublishedPatentStatics(callbacksFunction = {}) {
  return async (dispatch) => {
    const { onSuccess, onError } = callbacksFunction;
    dispatch({ type: GET_UNPUBLISHED_PATENT_STATISTICS, status: LOADING });
    try {
      let response = await mikeAxios.get(
        `${MGR_RT_URL}/unpublised_patents/stats`,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      onSuccess?.(response?.data);
      dispatch({
        type: GET_UNPUBLISHED_PATENT_STATISTICS,
        status: SUCCESS,
        payload: response?.data,
      });
    } catch (error) {
      // onResponseError?.(error);
      onError?.(error?.response?.data);
      dispatch({
        type: GET_UNPUBLISHED_PATENT_STATISTICS,
        status: ERROR,
        payload: error,
      });
    }
  };
}

export function deleteManageUnPublishedPatentData(
  body,
  callbacksFunction = {},
) {
  const { onSuccess } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: DELETE_UNPUBLISHED_PATENT, status: LOADING });
    try {
      const response = await mikeAxios.delete(
        `${MGR_RT_URL}/unpublised_patents/manage`,
        {
          data: {
            ...body,
          },
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message:
          response?.data?.message ?? "UnPublished Patent Deleted Successfully!",
      });
      dispatch({ type: DELETE_UNPUBLISHED_PATENT, status: SUCCESS });
      onSuccess && onSuccess(response?.data);
    } catch (error) {
      // onResponseError(error);
      notification["error"]({
        message: error?.response?.data?.payload?.detail || "An error occured",
      });
      dispatch({ type: DELETE_UNPUBLISHED_PATENT, status: ERROR });
    }
  };
}

export function updateUnPublishedPatentDeadlines(body, callbacksFunction = {}) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: UPDATE_UNPUBLISHED_PATENT_DEADLINES, status: LOADING });
    try {
      let response = await mikeAxios.put(
        `${MGR_RT_URL}/unpublised_patents/manage`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: "UnPublished Patent Updated Successfully!",
      });
      dispatch({ type: UPDATE_UNPUBLISHED_PATENT_DEADLINES, status: SUCCESS });
      onSuccess(response?.data);
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.message ?? "Something went wrong!",
        description:
          error?.response?.data?.payload ??
          "Unfortunately, an error occured while Adding Patent. Please, try again later.",
      });
      dispatch({ type: UPDATE_UNPUBLISHED_PATENT_DEADLINES, status: ERROR });
      // onResponseError(error);
      onError(error?.response?.data);
    }
  };
}

export function createUnPublishedPatentReport(body, callbacksFunction = {}) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: CREATE_UNPUBLISHED_PATENT_REPORT, status: LOADING });
    try {
      let response = await mikeAxios.post(
        `${MGR_RT_URL}/patent/new/report`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: response?.data?.message ?? "Report Queued Successfully!",
      });
      dispatch({ type: CREATE_UNPUBLISHED_PATENT_REPORT, status: SUCCESS });
      onSuccess?.(response?.data);
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.message ?? "Something went wrong!",
        description:
          error?.response?.data?.payload ??
          "Unfortunately, an error occured while Adding Patent. Please, try again later.",
      });
      onError?.(error?.response?.data);
      dispatch({ type: CREATE_UNPUBLISHED_PATENT_REPORT, status: ERROR });
    }
  };
}

export function downloadUnPublishedPatentPortfolio(
  body,
  callbacksFunction = {},
) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: DOWNLOAD_UNPUBLISHED_PATENT_PORTFOLIO, status: LOADING });
    try {
      let response = await mikeAxios.post(
        `${ROOT_URL}/download_portfolio/`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: response?.data?.message ?? "Report Queued Successfully!",
      });
      dispatch({
        type: DOWNLOAD_UNPUBLISHED_PATENT_PORTFOLIO,
        status: SUCCESS,
      });
      onSuccess?.(response?.data);
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.message ?? "Something went wrong!",
        description:
          error?.response?.data?.payload ??
          "Unfortunately, an error occured while Adding Patent. Please, try again later.",
      });
      onError?.(error?.response?.data);
      dispatch({ type: DOWNLOAD_UNPUBLISHED_PATENT_PORTFOLIO, status: ERROR });
    }
  };
}
