import React, { useState, useEffect } from "react";
import MikeContainer from "../../../../Watch/CommonComponents/Layout/MikeContainer";
import HeaderSection from "./HeaderSection";
import ContentSection from "./ContentSection";
import { useDispatch, useSelector } from "react-redux";
import {
  changeDeadlineUnPublishedPatentDeadlineType,
  changeDeadlineUnPublishedPatentResetAllFilter,
  getDashboardUnPublishedPatentFilter,
  getDashboardUnPublishedPatentList,
} from "../../../../../redux/actions/managerActions/unPublishedPatentDashboard/deadlinePatent";
import { date_format } from "../../../../Watch/watchFilter/utils/utils";
import dayjs from "dayjs";

const headerTabItems = [
  { key: "FORM_18", label: "Form 18" },
  { key: "PROVISIONAL_TO_COMPLETE", label: "Provisional To Complete" },
  { key: "PCT_CONVENTION", label: "PCT Convention" },
  { key: "DUE_DATE_FOR_FILING", label: "Due Date for Filing" },
  { key: "PROOF_OF_RIGHT_FORM_1", label: "Form 1" },
  { key: "FORM_3", label: "Form 3" },
  { key: "FORM_26", label: "Form 26" },
];

const UnPublishedPatentDashboard = (props) => {
  const [selected, setSelected] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [openReplyFiled, setOpenReplyFiled] = useState(false);
  const [openCustomReport, setOpenCustomReport] = useState(false);

  const { data, loading, metadata, deadline_type } = useSelector(
    (state) => state.managerState.unPublishedPatent.dashboard,
  );

  const {
    applicantsConfig,
    application_officesConfig,
    application_typesConfig,
    date_of_priorityConfig,
    filing_dateConfig,
    globalSearchValue,
    inventorsConfig,
    application_statusesConfig,
    due_dateConfig,
  } = useSelector(
    (state) => state.managerState.unPublishedPatent.dashboardFilters,
  );

  const dispatch = useDispatch();

  const generateFilterObject = () => {
    let filters = {
      contains_term: {
        value: globalSearchValue,
      },
      application_type: {
        value: application_typesConfig?.onlyValues?.length
          ? application_typesConfig?.onlyValues
          : [],
      },
      exclude_application_type: {
        value: application_typesConfig?.onlyExcludedValues?.length
          ? application_typesConfig?.onlyExcludedValues
          : [],
      },
      date_of_filing: {
        from: filing_dateConfig.value.from
          ? dayjs(filing_dateConfig.value.from).format(date_format)
          : "",
        to: filing_dateConfig.value.to
          ? dayjs(filing_dateConfig.value.to).format(date_format)
          : "",
      },
      applicant: {
        value: applicantsConfig?.onlyValues?.length
          ? applicantsConfig?.onlyValues
          : [],
      },
      exclude_applicant: {
        value: applicantsConfig?.onlyExcludedValues?.length
          ? applicantsConfig?.onlyExcludedValues
          : [],
      },
      inventor: {
        value: inventorsConfig?.onlyValues?.length
          ? inventorsConfig?.onlyValues
          : [],
      },
      exclude_inventor: {
        value: inventorsConfig?.onlyExcludedValues?.length
          ? inventorsConfig?.onlyExcludedValues
          : [],
      },
      application_office: {
        value: application_officesConfig?.onlyValues?.length
          ? application_officesConfig?.onlyValues
          : [],
      },
      exclude_application_office: {
        value: application_officesConfig?.onlyExcludedValues?.length
          ? application_officesConfig?.onlyExcludedValues
          : [],
      },
      priority_date: {
        from: date_of_priorityConfig.value.from
          ? dayjs(date_of_priorityConfig.value.from).format(date_format)
          : "",
        to: date_of_priorityConfig.value.to
          ? dayjs(date_of_priorityConfig.value.to).format(date_format)
          : "",
      },
      application_status: {
        value: application_statusesConfig?.onlyValues?.length
          ? application_statusesConfig?.onlyValues
          : [],
      },
      exclude_application_status: {
        value: application_statusesConfig?.onlyExcludedValues?.length
          ? application_statusesConfig?.onlyExcludedValues
          : [],
      },
      due_date: {
        from: due_dateConfig.value.from
          ? dayjs(due_dateConfig.value.from).format(date_format)
          : "",
        to: due_dateConfig.value.to
          ? dayjs(due_dateConfig.value.to).format(date_format)
          : "",
      },
    };
    return filters;
  };

  const isFilterSelected = () => {
    let filters = generateFilterObject();

    // Check for global search value
    if (filters?.contains_term?.value) {
      return true;
    }

    // Check for arrays with selected values
    if (
      filters?.application_type?.value?.length ||
      filters?.applicant?.value?.length ||
      filters?.inventor?.value?.length ||
      filters?.application_office?.value.length ||
      filters?.application_status?.value.length
    ) {
      return true;
    }

    // Check for arrays with Excluded values
    if (
      filters?.exclude_application_type?.value?.length ||
      filters?.exclude_applicant?.value?.length ||
      filters?.exclude_inventor?.value?.length ||
      filters?.exclude_application_office?.value.length ||
      filters?.exclude_application_status?.value.length
    ) {
      return true;
    }

    // Check for date ranges
    if (filters?.date_of_filing?.from || filters?.date_of_filing?.to) {
      return true;
    }

    if (filters?.priority_date?.from || filters?.priority_date?.to) {
      return true;
    }

    if (filters?.due_date?.from || filters?.due_date?.to) {
      return true;
    }

    // If no filters are selected
    return false;
  };

  const fetchAllFilters = (updatedDeadlineType = false) => {
    let filterKeys = [
      "application_types",
      "inventors",
      "applicants",
      "application_offices",
      "application_statuses",
    ];
    filterKeys.map((item) => {
      let body = {
        filter_key: item,
        deadline_type: updatedDeadlineType
          ? updatedDeadlineType
          : deadline_type,
        deadline_dashboard_flag: true,
      };
      dispatch(getDashboardUnPublishedPatentFilter(body));
    });
  };

  const getUnPublishedPatentData = (
    reset = false,
    onFirstPage = false,
    updatedDeadlineType = false,
  ) => {
    if (reset) {
      let body = {
        filter_flag: false,
        filters: {},
        deadline_type: updatedDeadlineType
          ? updatedDeadlineType
          : deadline_type,
      };
      dispatch(getDashboardUnPublishedPatentList(1, body));
      fetchAllFilters(updatedDeadlineType);
      setSelected([]);
      setSelectedRows([]);
      setSelectAll(false);
      setSelectedData({});
      return;
    }
    let generatedFilters = generateFilterObject();
    let isAnyFilterSelected = isFilterSelected();
    let body = {
      filter_flag: isAnyFilterSelected,
      filters: isAnyFilterSelected ? generatedFilters : {},
      deadline_type: updatedDeadlineType ? updatedDeadlineType : deadline_type,
    };
    dispatch(
      getDashboardUnPublishedPatentList(
        onFirstPage ? 1 : metadata?.pageNumber ?? 1,
        body,
      ),
    );
  };

  useEffect(() => {
    fetchAllFilters();
  }, []);

  useEffect(() => {
    getUnPublishedPatentData();
  }, []);

  const handleTabChange = (event) => {
    dispatch(changeDeadlineUnPublishedPatentDeadlineType(event));
    dispatch(changeDeadlineUnPublishedPatentResetAllFilter());
    getUnPublishedPatentData(true, false, event);
  };

  const commonProps = {
    isFilterSelected,
    generateFilterObject,
    selected,
    selectAll,
    selectedRows,
    setSelectAll,
    setSelected,
    setSelectedRows,
    getUnPublishedPatentData,
    setOpenReplyFiled,
  };

  const propsForHeader = {
    ...commonProps,
    tabItems: headerTabItems,
    onTabChange: handleTabChange,
    selected,
    selectAll,
    tableData: data,
    tableDataLoading: loading,
    selectedData,
    openReplyFiled,
    openCustomReport,
    setOpenCustomReport,
  };

  const propsForContent = {
    ...commonProps,
    setSelectedData,
  };

  return (
    <div style={{ padding: "10px", paddingTop: "0px" }}>
      <MikeContainer
        header={<HeaderSection {...propsForHeader} />}
        content={<ContentSection {...propsForContent} />}
        showHeader={false}
      />
    </div>
  );
};

export default UnPublishedPatentDashboard;
