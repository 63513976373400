import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardUnPublishedPatentList } from "../../../../../redux/actions/managerActions/unPublishedPatentDashboard/deadlinePatent";
import { CheckCircleTwoTone, FilterFilled } from "@ant-design/icons";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import { Col, notification, Row, Table, Tooltip } from "antd";
import {
  applicationStatusList,
  applicationTypeList,
} from "../Manage/common/constant";
import SelectWithExcludeFilterContainer from "./filtersContainer/SelectWithExcludeFilterContainer";
import SingleDateFilterContainer from "./filtersContainer/SingleDateFilterContainer";

const ContentSection = (props) => {
  const { data, loading, metadata, deadline_type } = useSelector(
    (state) => state.managerState.unPublishedPatent.dashboard,
  );

  const {
    application_typesConfig,
    applicantsConfig,
    filing_dateConfig,
    application_statusesConfig,
    due_dateConfig,
  } = useSelector(
    (state) => state.managerState.unPublishedPatent.dashboardFilters,
  );

  const { count, pageNumber } = metadata;
  const dispatch = useDispatch();

  const {
    dashboardAlert,
    selected,
    setSelected,
    selectAll,
    setSelectAll,
    selectedRows,
    setSelectedRows,
    generateFilterObject,
    isFilterSelected,
    setSelectedData,
    setOpenReplyFiled,
  } = props;

  const onSelectChange = (record, selected) => {
    if (selected) {
      let newSelected = [...selectedRows, record];
      setSelected(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    } else {
      let newSelected = selectedRows.filter((x) => x.id !== record.id);
      setSelected(newSelected.map((x) => x.id));
      setSelectedRows(newSelected);
    }
  };

  const rowSelection = {
    renderCell: function (checked, record, index, originNode) {
      return (
        <div
          className="tmapplied_selection_cols"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ margin: "4px" }}>
              {" "}
              {index + 1 + (pageNumber - 1) * 100}.{" "}
            </div>
            <div> {originNode}</div>
          </div>
        </div>
      );
    },
    selectedRowKeys: selected,
    onSelect: onSelectChange,
    selections: null,
    onSelectAll: (selected, selectedRows) => {
      setSelectAll(selected);
      setSelected(selectedRows);
      if (selected) {
        notification.open({
          message: "Deselect All Mark",
          description:
            "Click on the arrow near the select all checkbox to deselect all marks",
        });
      }
    },
    selections: selectAll
      ? [
          {
            key: "all-data",
            text: "Deselect All Data",
            onSelect: (changeableRowKeys) => {
              setSelectAll(false);
              setSelected([]);
              setSelectedRows([]);
            },
          },
        ]
      : false,
    getCheckboxProps: (record) => ({
      disabled: selectAll,
    }),
  };

  const handleNextpage = (pageNumber) => {
    let generatedFilters = generateFilterObject();
    let isAnyFilterSelected = isFilterSelected();
    let body = {
      filter_flag: isAnyFilterSelected,
      filters: isAnyFilterSelected ? generatedFilters : {},
      deadline_type: deadline_type,
    };
    dispatch(getDashboardUnPublishedPatentList(pageNumber, body));
  };

  const getColumnFilter = (dataIndex) => ({
    filterIcon: () => {
      let filtered = false;
      filtered =
        (dataIndex === "filing_date" && filing_dateConfig?.value?.dateString) ||
        (dataIndex === "due_date" && due_dateConfig?.value?.dateString) ||
        (dataIndex === "application_types" &&
          (application_typesConfig?.selectedValues?.length ||
            application_typesConfig?.excludeValues?.length)) ||
        (dataIndex === "application_statuses" &&
          (application_statusesConfig?.selectedValues?.length ||
            application_statusesConfig?.excludeValues?.length)) ||
        (dataIndex === "applicants" &&
          (applicantsConfig?.selectedValues?.length ||
            applicantsConfig?.excludeValues?.length));

      return (
        <FilterFilled
          id={`watch_table_filter_icon_id_${dataIndex}`}
          style={{
            color: filtered ? "#1677ff" : "black",
            marginRight: "",
          }}
        />
      );
    },
    filterDropdown: ({
      // setSelectedKeys,
      // selectedKeys,
      // confirm,
      // clearFilters,
      visible,
    }) => (
      <>
        {dataIndex === "application_types" && visible && (
          <SelectWithExcludeFilterContainer
            filteredData={application_typesConfig}
            key={application_typesConfig.key}
            loading={application_typesConfig.loading}
            tableView
          />
        )}

        {dataIndex === "application_statuses" && visible && (
          <SelectWithExcludeFilterContainer
            filteredData={application_statusesConfig}
            key={application_statusesConfig.key}
            loading={application_statusesConfig.loading}
            tableView
          />
        )}

        {dataIndex === "applicants" && visible && (
          <SelectWithExcludeFilterContainer
            filteredData={applicantsConfig}
            key={applicantsConfig.key}
            loading={applicantsConfig.loading}
            tableView
          />
        )}

        {dataIndex === "filing_date" && visible && (
          <SingleDateFilterContainer
            menu_label={"Date of Filing"}
            subtitle1={"Date of Filing"}
            key={"deadline_filing_date_filter"}
            filter_key_1="filing_date"
            value1={filing_dateConfig.value}
            tableView
          />
        )}
        {dataIndex === "due_date" && visible && (
          <SingleDateFilterContainer
            menu_label={"Due Date"}
            subtitle1={"Due Date"}
            key={"deadline_due_date_filter"}
            filter_key_1="due_date"
            value1={due_dateConfig.value}
            tableView
          />
        )}
      </>
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
  });

  const handleApplicationNumberClicked = (data) => {
    if (data) {
      window.open(
        "/manager/patent/unpublished-patent/patent-dashboard/" +
          data.unpublished_patent_id,
        "_blank",
      );
    }
  };

  const handleOpenReplyFiled = (item) => {
    setOpenReplyFiled(true);
    setSelectedData(item);
  };

  const columnsData = [
    {
      className: "tm-protect-column-1",
      title: "Application No. | Title",
      width: 150,
      fixed: "left",
      render: (text, record) => {
        return {
          children: (
            <p
              className="application-text alignL"
              onClick={() => handleApplicationNumberClicked(record)}
            >
              {checkValue(record?.application_number)
                ? record?.application_number
                : "N.A."}{" "}
              - {checkValue(record?.title) ? record?.title : "N.A."}
            </p>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Applicant Name",
      width: 120,
      ...getColumnFilter("applicants"),
      render: (text, record) => {
        return {
          children: (
            <div>
              <p className="alignL">
                <span>
                  {record?.applicants?.length
                    ? record?.applicants?.map((element, index, array) => (
                        <p key={element.id}>
                          {checkValue(element)
                            ? `${element.name}${
                                index + 1 === array?.length ? "" : " , "
                              }`
                            : "N.A."}
                        </p>
                      ))
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Filing Date",
      width: 60,
      ...getColumnFilter("filing_date"),
      render: (text, record) => {
        return {
          children: (
            <div>
              <p className="alignL">
                <span>
                  {checkValue(record?.filing_date)
                    ? record?.filing_date
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Due Date",
      width: 60,
      ...getColumnFilter("due_date"),
      render: (text, record) => {
        return {
          children: (
            <div>
              <p className="alignL">
                <span>
                  {checkValue(record?.due_date) ? record?.due_date : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>Priority Number |</span>
          <span>Priority Date</span>
        </div>
      ),
      width: 70,
      render: (text, record) => {
        return {
          children: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="alignL">
                <span>
                  {checkValue(record?.priority_number)
                    ? record?.priority_number
                    : "N.A."}
                </span>
              </p>
              <p className="alignL">
                <span>
                  {checkValue(record?.date_of_priority)
                    ? record?.date_of_priority
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <span>PCT Application Number |</span>
          <span>PCT Filing Date</span>
        </div>
      ),
      width: 100,
      render: (text, record) => {
        return {
          children: (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className="alignL">
                <span>
                  {checkValue(record?.pct_international_application_number)
                    ? record?.pct_international_application_number
                    : "N.A."}
                </span>
              </p>
              <p className="alignL">
                <span>
                  {checkValue(record?.date_of_filing_pct_application)
                    ? record?.date_of_filing_pct_application
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Application Type",
      width: 80,
      ...getColumnFilter("application_types"),
      render: (text, record) => {
        return {
          children: (
            <div>
              <p className="alignL">
                <span>
                  {checkValue(record?.application_type)
                    ? applicationTypeList.find(
                        (item) => item.value === record?.application_type,
                      )?.label
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Application Status",
      width: 80,
      ...getColumnFilter("application_statuses"),
      render: (text, record) => {
        return {
          children: (
            <div>
              <p className="alignL">
                <span>
                  {checkValue(record?.application_status)
                    ? applicationStatusList.find(
                        (item) => item.value === record?.application_status,
                      )?.label
                    : "N.A."}
                </span>
              </p>
            </div>
          ),
        };
      },
    },
    {
      className: "tm-protect-column-1",
      title: "Actions",
      width: 50,
      fixed: "right",
      render: (text, record) => {
        return (
          <div
            className="iconTable tmapplied_ll_actions"
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "15px",
              padding: "8px",
            }}
          >
            <Tooltip title="Reply Filed">
              <CheckCircleTwoTone
                style={{
                  cursor: "pointer",
                  color: "#1677ff",
                  fontSize: "20px",
                }}
                onClick={() => handleOpenReplyFiled(record)}
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const table = (
    <Row>
      <Col xs={{ span: 24 }} lg={{ span: 24 }} style={{ paddingTop: "16px" }}>
        <Table
          rowKey={(record) => record.id}
          className={"tm-protect-table"}
          bordered
          dataSource={data}
          columns={columnsData}
          scroll={
            dashboardAlert === ""
              ? { y: "calc(90vh - 260px)" }
              : {
                  y: "calc(90vh - 300px)",
                }
          }
          loading={loading}
          rowSelection={rowSelection}
          pagination={{
            pageSize: 100,
            showQuickJumper: true,
            showSizeChanger: false,
            defaultCurrent: 1,
            current: pageNumber,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} `,
            total: count,
            onChange: handleNextpage,
          }}
        />
      </Col>
    </Row>
  );

  return <div>{table}</div>;
};

export default ContentSection;
