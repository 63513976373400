import { notification } from "antd";
import mikeAxios from "../../../../configs/mikeAxios";
import { MGR_RT_URL } from "../../../../configs/rootConfigs";
import {
  ERROR,
  LOADING,
  SUCCESS,
} from "../../../../constants/commonConstants/generalConstants";
import {
  GET_DEADLINE_UNPUBLISHED_PATENT_LIST,
  UPDATE_UNPUBLISED_PATENT_DEADLINE_RESET_ALL_FILTERS,
  UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_APPLIED,
  UPDATE_DEADLINE_UNPUBLISHED_PATENT_DEADLINE_TYPE,
  UPDATE_UNPUBLISHED_PATENT_DEADLINE_GLOBAL_SEARCH_VALUE,
  UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_SELECTED_VALUE,
  UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_EXCLUDED_VALUE,
  UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_SEARCH_VALUE,
  UPDATE_UNPUBLISHED_PATENT_DEADLINE_DATE_FILTER_VALUE,
  UPDATE_UNPUBLISHED_PATENT_REPLY_FILED,
} from "./types";

export function getDashboardUnPublishedPatentList(
  pageNumber,
  body,
  callbacksFunction = {},
) {
  return async (dispatch) => {
    const { onSuccess, onError } = callbacksFunction;
    dispatch({ type: GET_DEADLINE_UNPUBLISHED_PATENT_LIST, status: LOADING });
    try {
      const data = {
        ...body,
      };

      let response = await mikeAxios.put(
        `${MGR_RT_URL}/unpublised_patents/deadlines/list_layout`,
        data,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
          params: {
            page: pageNumber,
            page_size: 100,
          },
        },
      );
      onSuccess?.(response?.data);
      dispatch({
        type: GET_DEADLINE_UNPUBLISHED_PATENT_LIST,
        status: SUCCESS,
        payload: response?.data,
        pageNumber,
      });
    } catch (error) {
      onError?.(error?.response?.data);
      dispatch({
        type: GET_DEADLINE_UNPUBLISHED_PATENT_LIST,
        status: ERROR,
        payload: error,
        pageNumber,
      });
    }
  };
}

export function getDashboardUnPublishedPatentFilter(
  body,
  callbacksFunction = {},
) {
  return async (dispatch) => {
    const { onSuccess, onError } = callbacksFunction;
    dispatch({
      type: `UNPUBLISHED_PATENT_DEADLINE_${body.filter_key}_FILTER`,
      status: LOADING,
      filter_key: body?.filter_key ?? "",
    });
    try {
      const params = {
        page: body?.page ?? 1,
        page_size: body?.page_size ?? 1000,
        search_term: body?.search_term ?? "",
        filter_type: body?.filter_key ?? "",
        deadline_type: body?.deadline_type ?? "",
        deadline_dashboard_flag: true,
      };
      let response = await mikeAxios.get(
        `${MGR_RT_URL}/unpublised_patents/filters`,
        {
          params: params,
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      onSuccess?.(response?.data);
      dispatch({
        type: `UNPUBLISHED_PATENT_DEADLINE_${body.filter_key}_FILTER`,
        status: SUCCESS,
        payload: response?.data,
        page: body?.page ?? 1,
        filter_key: body?.filter_key ?? "",
      });
    } catch (error) {
      onError?.(error?.response?.data);
      dispatch({
        type: `UNPUBLISHED_PATENT_DEADLINE_${body.filter_key}_FILTER`,
        status: ERROR,
        payload: error,
        filter_key: body?.filter_key ?? "",
        page: body?.page ?? 1,
      });
    }
  };
}

export function changeDeadlineUnPublishedPatentDeadlineType(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_DEADLINE_UNPUBLISHED_PATENT_DEADLINE_TYPE,
      value,
    });
  };
}
export function changeDeadlineUnPublishedPatentGlobalSearchValues(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_DEADLINE_GLOBAL_SEARCH_VALUE,
      value,
    });
  };
}

export function changeDeadlineUnPublishedPatentResetAllFilter() {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISED_PATENT_DEADLINE_RESET_ALL_FILTERS,
    });
  };
}

export function changeDeadlineUnPublishedPatentFilterApplied(value) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_APPLIED,
      value,
    });
  };
}

export function changeDeadlineUnPublishedPatentFilterSelectedValues(
  filter_key,
  values,
  onlyValues,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_SELECTED_VALUE,
      filter_key,
      values,
      onlyValues,
    });
  };
}

export function changeDeadlineUnPublishedPatentFilterExcludeValues(
  filter_key,
  values,
  onlyValues,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_EXCLUDED_VALUE,
      filter_key,
      values,
      onlyValues,
    });
  };
}

export function changeDeadlineUnPublishedPatentFilterSearchValues(
  filter_key,
  value,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_DEADLINE_FILTER_SEARCH_VALUE,
      filter_key,
      value,
    });
  };
}

export function changeDeadlineUnPublishedPatentDateFilterValues(
  filter_key,
  values,
) {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_UNPUBLISHED_PATENT_DEADLINE_DATE_FILTER_VALUE,
      filter_key,
      values,
    });
  };
}

export function updateUnPublishedPatentReplyFiled(
  body,
  callbacksFunction = {},
) {
  const { onSuccess, onError } = callbacksFunction;
  return async (dispatch) => {
    dispatch({ type: UPDATE_UNPUBLISHED_PATENT_REPLY_FILED, status: LOADING });
    try {
      let response = await mikeAxios.put(
        `${MGR_RT_URL}/unpublised_patents/manage`,
        body,
        {
          headers: { Authorization: "Token " + localStorage.getItem("token") },
        },
      );
      notification["success"]({
        message: "Reply Filed Successfully!",
      });
      dispatch({
        type: UPDATE_UNPUBLISHED_PATENT_REPLY_FILED,
        status: SUCCESS,
      });
      onSuccess(response?.data);
    } catch (error) {
      notification["error"]({
        message: error?.response?.data?.message ?? "Something went wrong!",
        description:
          error?.response?.data?.payload ??
          "Unfortunately, an error occured while Adding Patent. Please, try again later.",
      });
      dispatch({ type: UPDATE_UNPUBLISHED_PATENT_REPLY_FILED, status: ERROR });
      onError(error?.response?.data);
    }
  };
}
