import { FilterFilled, MinusCircleOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Input, Row, Tooltip } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectWithExcludeFilterContainer from "./filtersContainer/SelectWithExcludeFilterContainer";
import SingleDateFilterContainer from "./filtersContainer/SingleDateFilterContainer";
import {
  changeUnPublishedPatentFilterApplied,
  changeUnPublishedPatentGlobalSearchValues,
  changeUnPublishedPatentResetAllFilter,
  downloadUnPublishedPatentPortfolio,
} from "../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";

const PrimaryFilters = (props) => {
  const {
    tableDataLoading,
    setSelectAll,
    setSelected,
    setSelectedRows,
    selected,
    tableData,
    selectAll,
    setOpenCustomReport,
  } = props;
  const dispatch = useDispatch();

  const filterSelected = props?.isFilterSelected?.();

  const {
    inventorsConfig,
    application_officesConfig,
    date_of_priorityConfig,
    globalSearchValue,
    isFilterApplied,
  } = useSelector(
    (state) => state.managerState.unPublishedPatent.manageFilters,
  );

  const handleGlobalSearchValue = (event) => {
    dispatch(changeUnPublishedPatentGlobalSearchValues(event.target.value));
  };

  const handleApplyFilters = () => {
    props?.getUnPublishedPatentData?.(false, true);
    dispatch(changeUnPublishedPatentFilterApplied(true));
  };

  const handleResetFilters = () => {
    dispatch(changeUnPublishedPatentResetAllFilter());
    props?.getUnPublishedPatentData?.(true);
  };

  const handleCustomReport = () => {
    setOpenCustomReport(true);
  };

  const handleDownloadPortfolio = () => {
    let body = {
      product: "manager",
      subscription: "unpublished_patent",
    };
    dispatch(downloadUnPublishedPatentPortfolio(body));
  };

  const manageButtonSection = (
    <>
      <Tooltip title="Deselect All">
        <Button
          danger
          onClick={() => {
            setSelectAll(false);
            setSelected([]);
            setSelectedRows([]);
          }}
          disabled={!selected.length || !tableData.length}
          icon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MinusCircleOutlined style={{ margin: 0, fontSize: 16 }} />
            </div>
          }
        />
      </Tooltip>
      <Button onClick={handleDownloadPortfolio}>Download Portfolio</Button>
      <Badge
        overflowCount={9999}
        count={selectAll ? "ALL" : selected.length}
        style={{ marginTop: "5px" }}
      >
        <Button
          onClick={handleCustomReport}
          disabled={!selected.length || !tableData.length}
        >
          Create Report
        </Button>
      </Badge>
    </>
  );

  const firstRow = (
    <Row style={{ width: "100%" }}>
      <Col xs={24} sm={24} md={24} lg={18} style={{ display: "flex", gap: 13 }}>
        <Input
          placeholder={
            "Search Application No., Title, Priority Number, PCT Application No."
          }
          onChange={(event) => handleGlobalSearchValue(event)}
          value={globalSearchValue}
          allowClear
          className="search-value-input-field"
          style={{
            width: 450,
            background: "#F5F6F8",
            borderRadius: "0px",
          }}
        />
        <Button
          type="primary"
          icon={<FilterFilled />}
          disabled={!filterSelected || tableDataLoading}
          onClick={handleApplyFilters}
          loading={tableDataLoading}
        >
          Apply
        </Button>
        <Button
          type="text"
          danger
          onClick={() => handleResetFilters()}
          disabled={!isFilterApplied}
        >
          Reset All
        </Button>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={24}
        lg={6}
        style={{ display: "flex", gap: 13, justifyContent: "flex-end" }}
      >
        {manageButtonSection}
      </Col>
    </Row>
  );

  const secondRow = (
    <Row style={{ width: "100%", marginTop: "15px" }}>
      <Col span={24} style={{ display: "flex", gap: 10 }}>
        <SelectWithExcludeFilterContainer
          filteredData={inventorsConfig}
          key={inventorsConfig.key}
          loading={inventorsConfig.loading}
        />
        <SelectWithExcludeFilterContainer
          filteredData={application_officesConfig}
          key={application_officesConfig.key}
          loading={application_officesConfig.loading}
        />
        <SingleDateFilterContainer
          menu_label={"Date of Priority"}
          subtitle1={"Date of Priority"}
          key={"manager_date_of_priority_filter"}
          filter_key_1="date_of_priority"
          value1={date_of_priorityConfig.value}
        />
      </Col>
    </Row>
  );

  return (
    <>
      <header className="General-Header">
        <Row style={{ padding: "10px 0px" }}>
          {firstRow}
          {secondRow}
        </Row>
      </header>
    </>
  );
};

export default PrimaryFilters;
