import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Typography,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import {
  addUnPublishedPatent,
  updateUnPublishedPatent,
} from "../../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";
import {
  applicationOfficeList,
  applicationStatusList,
  applicationTypeList,
} from "../common/constant";

const { Title, Text } = Typography;

const AddEditPatent = (props) => {
  const {
    open,
    setOpen,
    type,
    makeItFullScreen = false,
    selectedDataForEdit,
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const selectedApplicationType = Form.useWatch("application_type", form);

  const isShowPostDatedBy = selectedApplicationType === "ORDINARY_PROVISIONAL";
  const isShowRFEFiledDate = [
    "ORDINARY_COMPLETE",
    "DIVISIONAL",
    "PARIS_CONVENTION",
    "PCT_NATIONAL_PHASE",
  ].includes(selectedApplicationType);
  const isShowForm1CompliedDate = [
    "ORDINARY_COMPLETE",
    "DIVISIONAL",
    "PARIS_CONVENTION",
    "PCT_NATIONAL_PHASE",
  ].includes(selectedApplicationType);
  const isShowForm3CompliedDate = [
    "ORDINARY_COMPLETE",
    "DIVISIONAL",
    "PARIS_CONVENTION",
    "PCT_NATIONAL_PHASE",
  ].includes(selectedApplicationType);
  const isShowForm26CompliedDate = [
    "ORDINARY_COMPLETE",
    "DIVISIONAL",
    "PARIS_CONVENTION",
    "PCT_NATIONAL_PHASE",
  ].includes(selectedApplicationType);

  const isShowParentApplicationNumber =
    selectedApplicationType === "DIVISIONAL";
  const isShowPriorityCountry = [
    "PARIS_CONVENTION",
    "PCT_NATIONAL_PHASE",
  ].includes(selectedApplicationType);
  const isShowPriorityNumber = [
    "PARIS_CONVENTION",
    "PCT_NATIONAL_PHASE",
  ].includes(selectedApplicationType);
  const isShowPriorityDate = [
    "PARIS_CONVENTION",
    "PCT_NATIONAL_PHASE",
  ].includes(selectedApplicationType);
  const isShowPCTApplicationNumber =
    selectedApplicationType === "PCT_NATIONAL_PHASE";
  const isShowPCTApplicationFilingDate =
    selectedApplicationType === "PCT_NATIONAL_PHASE";

  const formInitialValues = useMemo(() => {
    if (type === "EDIT" && selectedDataForEdit) {
      return {
        applicants:
          selectedDataForEdit?.applicants &&
          selectedDataForEdit.applicants.length >= 2
            ? selectedDataForEdit.applicants
            : selectedDataForEdit?.applicants?.length === 1
              ? [...selectedDataForEdit.applicants, { name: "" }]
              : [{ name: "" }, { name: "" }],
        inventors:
          selectedDataForEdit?.inventors &&
          selectedDataForEdit.inventors.length >= 2
            ? selectedDataForEdit.inventors
            : selectedDataForEdit?.inventors?.length === 1
              ? [...selectedDataForEdit.inventors, { name: "" }]
              : [{ name: "" }, { name: "" }],
        application_number: selectedDataForEdit?.application_number || "",
        title_of_invention: selectedDataForEdit?.title || "",
        application_type: selectedDataForEdit?.application_type || null,
        application_office: selectedDataForEdit?.application_office || null,
        post_dated_by: selectedDataForEdit?.post_dated_by || "",
        application_filing_date: selectedDataForEdit?.filing_date
          ? dayjs(selectedDataForEdit?.filing_date, "DD/MM/YYYY")
          : "",
        priority_date: selectedDataForEdit?.date_of_priority
          ? dayjs(selectedDataForEdit?.date_of_priority, "DD/MM/YYYY")
          : "",
        priority_country: selectedDataForEdit?.priority_country || "",
        priority_number: selectedDataForEdit?.priority_number || "",
        date_of_filing_pct_application:
          selectedDataForEdit?.date_of_filing_pct_application
            ? dayjs(
                selectedDataForEdit?.date_of_filing_pct_application,
                "DD/MM/YYYY",
              )
            : "",
        pct_international_application_number:
          selectedDataForEdit?.pct_international_application_number || "",
        application_status: selectedDataForEdit?.application_status || null,
        parent_application_number:
          selectedDataForEdit?.parent_application_number || "",
      };
    } else {
      return {
        applicants: [{ name: "" }, { name: "" }],
        inventors: [{ name: "" }, { name: "" }],
        application_number: "",
        title_of_invention: "",
        application_type: null,
        application_office: null,
        post_dated_by: "",
        application_filing_date: "",
        priority_date: "",
        priority_country: "",
        priority_number: "",
        date_of_filing_pct_application: "",
        pct_international_application_number: "",
        application_status: null,
        parent_application_number: "",
        rfe_filed_date: "",
        form_1_complied_date: "",
        form_3_complied_date: "",
        form_26_complied_date: "",
      };
    }
  }, [type, selectedDataForEdit]);

  const validateApplicationNumber = (_, value) => {
    const { application_type, application_filing_date } = form.getFieldsValue([
      "application_type",
      "application_filing_date",
    ]);

    // New Application Number regex valid after 2015
    const newApplicationNumberRegex = /^\d{4}[1-4][1-9]\d{6}$/;

    // Old Application Number regex valid before 2015
    const oldApplicationNumberRegex =
      /^[0-9]{5}\/(?:[a-zA-Z]{3}|[a-zA-Z]{5})\/\d{4}(?:\s*,\s*[0-9]{5}\/(?:[a-zA-Z]{3}|[a-zA-Z]{5})\/\d{4})*$/;

    // Check regex format first
    // Check format based on presence of '/'
    if (value) {
      if (value.includes("/") && !oldApplicationNumberRegex.test(value)) {
        return Promise.reject(
          new Error(
            "Please enter valid application numbers and avoid trailing commas.",
          ),
        );
      } else if (
        !value.includes("/") &&
        !newApplicationNumberRegex.test(value)
      ) {
        return Promise.reject(
          new Error(
            "Please enter a valid application number in the format: YYYYJTNNNNNN",
          ),
        );
      }
    }

    if (
      ["ORDINARY_PROVISIONAL", "ORDINARY_COMPLETE"].includes(
        application_type,
      ) &&
      !value
    ) {
      return Promise.reject(
        new Error("Application Number is required for ORDINARY types."),
      );
    }

    if (application_filing_date && !value) {
      return Promise.reject(
        new Error("Application Number is required if Filing Date is present."),
      );
    }

    if (application_type === "DIVISIONAL" && !value) {
      return Promise.reject(
        new Error("Application Number is required for DIVISIONAL."),
      );
    }

    return Promise.resolve();
  };

  const validatePCTFields = (_, value) => {
    const {
      application_type,
      pct_international_application_number,
      date_of_filing_pct_application,
    } = form.getFieldsValue([
      "application_type",
      "pct_international_application_number",
      "date_of_filing_pct_application",
    ]);

    const pctInternationalApplicationNumberRegex =
      /^PCT\/[A-Za-z]{2}\d{4}\/\d{6}$/i;

    if (application_type === "PCT_NATIONAL_PHASE") {
      if (_.field === "pct_international_application_number") {
        // regex validation
        if (
          !pctInternationalApplicationNumberRegex.test(
            pct_international_application_number,
          )
        ) {
          return Promise.reject(
            new Error(
              "Please enter a valid PCT International Application Number in the format: PCT/XY1234/123456.",
            ),
          );
        }
      }

      if (
        !pct_international_application_number ||
        !date_of_filing_pct_application
      ) {
        return Promise.reject(
          new Error(
            "Both PCT International Application Number and Date of Filing PCT Application is Required.",
          ),
        );
      }
    }

    return Promise.resolve();
  };

  const validateParisConventionFields = async (_, value) => {
    const { priority_date, priority_number, application_type } =
      form.getFieldsValue([
        "priority_date",
        "priority_number",
        "application_type",
      ]);

    if (application_type === "PARIS_CONVENTION") {
      if (priority_date && priority_number) {
        return Promise.resolve();
      }

      return Promise.reject(
        new Error("Both Priority Date and Priority Number are required."),
      );
    }

    return Promise.resolve();
  };

  const validateParentApplicationNumber = (_, value) => {
    const application_type = form.getFieldValue("application_type");

    // New Application Number regex valid after 2015
    const newParentApplicationNumberRegex = /^\d{4}[1-4][1-9]\d{6}$/;

    // Old Application Number regex valid before 2015
    const oldParentApplicationNumberRegex =
      /^[0-9]{5}\/(?:[a-zA-Z]{3}|[a-zA-Z]{5})\/\d{4}(?:\s*,\s*[0-9]{5}\/(?:[a-zA-Z]{3}|[a-zA-Z]{5})\/\d{4})*$/;

    // Check regex format first
    // Check format based on presence of '/'
    if (value) {
      if (value.includes("/") && !oldParentApplicationNumberRegex.test(value)) {
        return Promise.reject(
          new Error(
            "Please enter valid parent application numbers and avoid trailing commas.",
          ),
        );
      } else if (
        !value.includes("/") &&
        !newParentApplicationNumberRegex.test(value)
      ) {
        return Promise.reject(
          new Error(
            "Please enter a valid parent application number in the format: YYYYJTNNNNNN",
          ),
        );
      }
    }

    if (application_type === "DIVISIONAL" && !value) {
      return Promise.reject(
        new Error("Parent Application Number is required for DIVISIONAL."),
      );
    }

    return Promise.resolve();
  };

  const validateFilingDate = (_, value) => {
    const applicationNumber = form.getFieldValue("application_number");
    const application_type = form.getFieldValue("application_type");
    if (applicationNumber && !value) {
      return Promise.reject(
        new Error("Filing Date is required if Application Number is present."),
      );
    }
    if (
      (application_type === "ORDINARY_PROVISIONAL" ||
        application_type === "ORDINARY_COMPLETE") &&
      !value
    ) {
      return Promise.reject(
        new Error("Filing Date is required for Ordinary types."),
      );
    }
    if (application_type === "DIVISIONAL" && !value) {
      return Promise.reject(
        new Error("Filing Date is required for DIVISIONAL."),
      );
    }
    return Promise.resolve();
  };

  const onClose = () => {
    setOpen(false);
    form.resetFields();
  };

  const convertNames = (data) => {
    // Check if data is an array and not null or undefined
    if (!Array.isArray(data) || data.length === 0) {
      return []; // Return an empty array if data is not valid
    }

    // Map through the array to extract names
    return data
      .map((item) => {
        // Check if item is an object and has a valid 'name' property
        if (
          item &&
          typeof item === "object" &&
          "name" in item &&
          typeof item.name === "string" &&
          item.name.trim().length > 0
        ) {
          return item.name.trim(); // Trim any extra spaces from the name
        }
        return null; // Return null if the item is invalid
      })
      .filter((name) => name !== null); // Filter out any null values
  };

  const handleSubmit = (values) => {
    setLoading(true);

    let body = {
      application_number: values.application_number || "",
      title_of_invention: values.title_of_invention || "",
      application_type: values.application_type || "",
      application_office: values.application_office || "",
      application_status: values.application_status || "",
      applicants: convertNames(values.applicants) || [],
      inventors: convertNames(values.inventors) || [],
      application_filing_date: values.application_filing_date
        ? dayjs(values.application_filing_date).format("DD-MM-YYYY")
        : "",
      post_dated_by: 0,
      rfe_filed_date: "",
      form_1_complied_date: "",
      form_3_complied_date: "",
      form_26_complied_date: "",
      parent_application_number: "",
      priority_country: "",
      priority_date: "",
      pct_international_application_number: "",
      date_of_filing_pct_application: "",
    };

    if (isShowPostDatedBy && values.post_dated_by) {
      body.post_dated_by = Number(values.post_dated_by);
    }

    if (isShowParentApplicationNumber && values.parent_application_number) {
      body.parent_application_number = values.parent_application_number;
    }
    if (isShowPriorityCountry && values.priority_country) {
      body.priority_country = values.priority_country;
    }
    if (isShowPriorityNumber && values.priority_number) {
      body.priority_number = values.priority_number;
    }
    if (isShowPriorityDate && values.priority_date) {
      body.priority_date = dayjs(values.priority_date).format("DD-MM-YYYY");
    }
    if (
      isShowPCTApplicationNumber &&
      values.pct_international_application_number
    ) {
      body.pct_international_application_number =
        values.pct_international_application_number;
    }
    if (
      isShowPCTApplicationFilingDate &&
      values.date_of_filing_pct_application
    ) {
      body.date_of_filing_pct_application = dayjs(
        values.date_of_filing_pct_application,
      ).format("DD-MM-YYYY");
    }

    if (type === "ADD") {
      if (isShowRFEFiledDate && values.rfe_filed_date) {
        body.rfe_filed_date = dayjs(values.rfe_filed_date).format("DD-MM-YYYY");
      }
      if (isShowForm1CompliedDate && values.form_1_complied_date) {
        body.form_1_complied_date = dayjs(values.form_1_complied_date).format(
          "DD-MM-YYYY",
        );
      }
      if (isShowForm3CompliedDate && values.form_3_complied_date) {
        body.form_3_complied_date = dayjs(values.form_3_complied_date).format(
          "DD-MM-YYYY",
        );
      }
      if (isShowForm26CompliedDate && values.form_26_complied_date) {
        body.form_26_complied_date = dayjs(values.form_26_complied_date).format(
          "DD-MM-YYYY",
        );
      }
      dispatch(
        addUnPublishedPatent(body, {
          onSuccess: () => {
            setLoading(false);
            props?.getUnPublishedPatentData?.();
            onClose?.();
          },
          onError: () => {
            setLoading(false);
          },
        }),
      );
    } else if (type === "EDIT") {
      body.pk = selectedDataForEdit?.id || null;
      dispatch(
        updateUnPublishedPatent(body, {
          onSuccess: () => {
            setLoading(false);
            props?.getUnPublishedPatentData?.();
            onClose?.();
          },
          onError: () => {
            setLoading(false);
          },
        }),
      );
    } else {
      return null;
    }
  };

  const formTitle = useMemo(
    () => (
      <Title level={4}>
        {type === "ADD"
          ? "Add Patent (Unpublished)"
          : "Edit Patent (Unpublished)"}
      </Title>
    ),
    [type],
  );

  const formLabel = (title) => {
    return <Text strong>{title}</Text>;
  };

  const handleApplicationTypeChange = (value) => {
    // Clear validation errors without emptying field values
    form.setFields([
      { name: "priority_date", errors: [] },
      { name: "priority_number", errors: [] },
      { name: "pct_international_application_number", errors: [] },
      { name: "date_of_filing_pct_application", errors: [] },
      { name: "application_number", errors: [] },
      { name: "application_filing_date", errors: [] },
      { name: "parent_application_number", errors: [] },
    ]);
  };

  // Determine the number of columns per row based on the makeItFullScreen prop
  const columnSpan = makeItFullScreen ? 8 : 12;

  return (
    <>
      <Modal
        title={formTitle}
        centered
        open={open}
        onCancel={loading ? null : onClose}
        footer={[
          <Button
            key="cancel"
            disabled={loading}
            type="default"
            onClick={onClose}
          >
            Cancel
          </Button>,
          <Button
            loading={loading}
            key="submit"
            type="primary"
            disabled={loading}
            onClick={() => form.submit()}
          >
            {type === "ADD" ? "Save" : "Update"}
          </Button>,
        ]}
        bodyStyle={{ maxHeight: "80vh", overflowY: "auto" }}
        width={makeItFullScreen ? "100vw" : 1000} // Full width if makeItFullScreen is true
        closable={!loading}
      >
        <Form
          form={form}
          id="bulk-upload"
          layout="vertical"
          style={{ margin: "15px 10px" }}
          onFinish={handleSubmit}
          initialValues={formInitialValues}
        >
          <Spin spinning={loading}>
            <Row gutter={[8, 0]}>
              <Col xs={24} sm={24} md={columnSpan}>
                <Form.Item
                  label={formLabel("Application Type")}
                  name="application_type"
                  rules={[
                    {
                      required: true,
                      message: "Please select Application Type.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Enter Application Type"
                    // options={applicationTypeList}
                    options={
                      type === "EDIT" &&
                      (selectedApplicationType === "ORDINARY_PROVISIONAL" ||
                        selectedApplicationType === "ORDINARY_COMPLETE")
                        ? applicationTypeList.filter(
                            (option) =>
                              option.value === "ORDINARY_COMPLETE" ||
                              option.value === "ORDINARY_PROVISIONAL",
                          )
                        : applicationTypeList
                    }
                    allowClear
                    onChange={handleApplicationTypeChange}
                    disabled={
                      type === "EDIT" &&
                      !(
                        selectedApplicationType === "ORDINARY_PROVISIONAL" ||
                        selectedApplicationType === "ORDINARY_COMPLETE"
                      )
                    }
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={columnSpan}>
                <Form.Item
                  label={formLabel("Application Number")}
                  name="application_number"
                  dependencies={["application_filing_date"]}
                  rules={[
                    {
                      validator: validateApplicationNumber,
                    },
                  ]}
                >
                  <Input placeholder="Enter Application Number" />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={columnSpan}>
                <Form.Item
                  label={formLabel("Filing Date")}
                  name="application_filing_date"
                  dependencies={["application_number"]}
                  rules={[
                    {
                      validator: validateFilingDate,
                    },
                  ]}
                >
                  <DatePicker format={"DD/MM/YYYY"} style={{ width: "100%" }} />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={columnSpan}>
                <Form.Item
                  label={formLabel("Title")}
                  name="title_of_invention"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Title.",
                    },
                  ]}
                >
                  <Input placeholder="Enter Title" />
                </Form.Item>
              </Col>

              {/* Section to add multiple applicants name */}
              <Col xs={24} sm={24} md={24}>
                <Form.List name="applicants">
                  {(fields, { add, remove }) => (
                    <>
                      <Row gutter={[16, 0]}>
                        {fields.map((field, index) => (
                          <Col
                            xs={22}
                            sm={columnSpan}
                            // md={12}
                            key={field.key}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Item
                              {...field}
                              label={formLabel(`Applicant Name-${index + 1}`)}
                              name={[field.name, "name"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Please enter Applicant Name.",
                                },
                              ]}
                              style={{ flex: 1 }}
                            >
                              <Input
                                placeholder="Enter Applicant Name"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>

                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Col>
                        ))}
                      </Row>
                      <Form.Item
                        style={{ marginTop: "0px", marginBottom: "10px" }}
                      >
                        <Button
                          type="link"
                          onClick={() => add()}
                          style={{ padding: "0px" }}
                          icon={<PlusOutlined />}
                        >
                          Add Another Application
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              {/* Section to add multiple application name */}

              {/* Section to add multiple inventors */}
              <Col xs={24} sm={24} md={24}>
                <Form.List name="inventors">
                  {(fields, { add, remove }) => (
                    <>
                      <Row gutter={[16, 0]}>
                        {fields.map((field, index) => (
                          <Col
                            xs={22}
                            sm={columnSpan}
                            // md={12}
                            key={field.key}
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <Form.Item
                              {...field}
                              label={formLabel(`Inventor Name-${index + 1}`)}
                              name={[field.name, "name"]}
                              rules={[
                                {
                                  required: false,
                                  message: "Please enter Inventor Name",
                                },
                              ]}
                              style={{ flex: 1 }}
                            >
                              <Input
                                placeholder="Enter Inventor Name"
                                style={{ width: "100%" }}
                              />
                            </Form.Item>

                            {fields.length > 1 ? (
                              <MinusCircleOutlined
                                style={{
                                  marginLeft: "10px",
                                  fontSize: "16px",
                                  cursor: "pointer",
                                }}
                                onClick={() => remove(field.name)}
                              />
                            ) : null}
                          </Col>
                        ))}
                      </Row>
                      <Form.Item
                        style={{ marginTop: "0px", marginBottom: "10px" }}
                      >
                        <Button
                          type="link"
                          onClick={() => add()}
                          style={{ padding: "0px" }}
                          icon={<PlusOutlined />}
                        >
                          Add Another Inventor
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              {/* Section to add multiple inventors */}

              <Col xs={24} sm={24} md={columnSpan}>
                <Form.Item
                  label={formLabel("Application Office")}
                  name="application_office"
                  rules={[
                    {
                      required: false,
                      message: "Please enter Application Office.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Enter Application Office"
                    options={applicationOfficeList}
                    allowClear
                  />
                </Form.Item>
              </Col>

              <Col xs={24} sm={24} md={columnSpan}>
                <Form.Item
                  label={formLabel("Application Status")}
                  name="application_status"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Application Status.",
                    },
                  ]}
                >
                  <Select
                    placeholder="Enter Application Status"
                    options={applicationStatusList}
                    allowClear
                  />
                </Form.Item>
              </Col>
              {isShowPostDatedBy && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("Post Dated by (1-180 days)")}
                    name="post_dated_by"
                    rules={[
                      {
                        required: false,
                        message: "Please enter Post Dated By.",
                      },
                      {
                        validator: (_, value) => {
                          if (!value || (value >= 0 && value <= 180)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Value must be between 1 and 180."),
                          );
                        },
                      },
                    ]}
                  >
                    <Input
                      min={1}
                      max={180}
                      placeholder="Enter Post dated By ( No. of days )"
                    />
                  </Form.Item>
                </Col>
              )}

              {isShowPriorityCountry && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("Priority Country")}
                    name="priority_country"
                    rules={[
                      {
                        required: false,
                        message: "Please enter Priority Country.",
                      },
                    ]}
                  >
                    <Input placeholder="Enter Priority Country" />
                  </Form.Item>
                </Col>
              )}

              {isShowPriorityNumber && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("Priority Number")}
                    name="priority_number"
                    dependencies={["priority_date"]}
                    rules={[{ validator: validateParisConventionFields }]}
                  >
                    <Input placeholder="Enter Priority Number" />
                  </Form.Item>
                </Col>
              )}

              {isShowPriorityDate && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("Priority Date")}
                    name="priority_date"
                    dependencies={["priority_number"]}
                    rules={[{ validator: validateParisConventionFields }]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}

              {isShowPCTApplicationNumber && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("PCT Application Number")}
                    name="pct_international_application_number"
                    dependencies={["date_of_filing_pct_application"]}
                    rules={[{ validator: validatePCTFields }]}
                  >
                    <Input placeholder="Enter PCT Application Number" />
                  </Form.Item>
                </Col>
              )}

              {isShowPCTApplicationFilingDate && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("PCT Application Filing Date")}
                    name="date_of_filing_pct_application"
                    dependencies={["pct_international_application_number"]}
                    rules={[{ validator: validatePCTFields }]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}

              {isShowParentApplicationNumber && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("Parent Application Number")}
                    name="parent_application_number"
                    rules={[{ validator: validateParentApplicationNumber }]}
                  >
                    <Input placeholder="Enter Parent Application Number" />
                  </Form.Item>
                </Col>
              )}
              {type === "ADD" && isShowRFEFiledDate && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("RFE Filed Date")}
                    name="rfe_filed_date"
                    rules={[
                      {
                        required: false,
                        message: "Please enter RFE Filed Date.",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
              {type === "ADD" && isShowForm1CompliedDate && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("Form 1 Complied Date")}
                    name="form_1_complied_date"
                    rules={[
                      {
                        required: false,
                        message: "Please enter Form 1 Complied Date.",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
              {type === "ADD" && isShowForm3CompliedDate && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("Form 3 Complied Date")}
                    name="form_3_complied_date"
                    rules={[
                      {
                        required: false,
                        message: "Please enter Form 3 Complied Date.",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
              {type === "ADD" && isShowForm26CompliedDate && (
                <Col xs={24} sm={24} md={columnSpan}>
                  <Form.Item
                    label={formLabel("Form 26 Complied Date")}
                    name="form_26_complied_date"
                    rules={[
                      {
                        required: false,
                        message: "Please enter Form 26 Complied Date.",
                      },
                    ]}
                  >
                    <DatePicker
                      format={"DD/MM/YYYY"}
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Spin>
        </Form>
      </Modal>
    </>
  );
};
export default AddEditPatent;
