import { Col, Row, Tabs, Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import PrimaryFilters from "./PrimaryFilters";
import AddReplyFiled from "./formComponents/AddReplyFiled";
import CreateReportContainer from "../Manage/common/CreateReportContainer";

const { Title } = Typography;

const HeaderSection = (props) => {
  const {
    tabItems = [],
    onTabChange,
    selectedData,
    openReplyFiled,
    setOpenReplyFiled,
    getUnPublishedPatentData,
    openCustomReport,
    setOpenCustomReport,
    selected,
    selectAll,
    generateFilterObject,
  } = props;

  const header = <Title level={3}>Dashboard</Title>;

  const { deadline_type } = useSelector(
    (state) => state.managerState.unPublishedPatent.dashboard,
  );

  const tabSection = (
    <Tabs
      defaultActiveKey={deadline_type}
      items={tabItems}
      onChange={onTabChange}
    />
  );

  return (
    <div>
      <Row>
        <Col>{header}</Col>
      </Row>
      <Row>
        <Col span={24}>{tabSection}</Col>
      </Row>
      <Row>
        <Col span={24}>
          {openReplyFiled && (
            <AddReplyFiled
              open={openReplyFiled}
              setOpen={setOpenReplyFiled}
              selectedDataForReplyFiled={selectedData}
              refetch={getUnPublishedPatentData}
            />
          )}
          {openCustomReport && (
            <CreateReportContainer
              open={openCustomReport}
              setOpen={setOpenCustomReport}
              type="DEADLINE"
              selected={selected}
              selectAll={selectAll}
              generateFilterObject={generateFilterObject}
            />
          )}
          <PrimaryFilters {...props} />
        </Col>
      </Row>
    </div>
  );
};

export default HeaderSection;
