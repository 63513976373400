import {
  DeleteOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Badge, Button, Card, Col, Row, Tooltip, Typography } from "antd";
import React from "react";
import BulkUpload from "./bulkUpload/BulkUpload";
import AddEditPatent from "./formComponents/AddEditPatent";
import PrimaryFilters from "./PrimaryFilters";
import { useDispatch, useSelector } from "react-redux";
import { deleteManageUnPublishedPatentData } from "../../../../../redux/actions/managerActions/unPublishedPatentDashboard/managePatent";
import CreateReportContainer from "./common/CreateReportContainer";

const { Title, Text } = Typography;

const HeaderSection = (props) => {
  const {
    setOpenAddForm,
    setOpenBulkUpload,
    setOpenEditForm,
    selectAll,
    selected,
    tableData,
    openBulkUpload,
    openAddForm,
    openEditForm,
    getUnPublishedPatentData,
    selectedDataForEdit,
    openCustomReport,
    setOpenCustomReport,
    generateFilterObject,
  } = props;

  const dispatch = useDispatch();

  const { stats } = useSelector(
    (state) => state.managerState.unPublishedPatent.manage,
  );

  const header = <Title level={3}>Manage Patents ( Unpublished )</Title>;

  const handleSuccess = () => {
    props?.setSelectAll(false);
    props?.setSelected([]);
    props?.setSelectedRows([]);
    props?.getUnPublishedPatentData?.();
  };

  const handleDelete = () => {
    const generatedFilters = props?.generateFilterObject?.();
    const baseBody = {
      filter_flag: false,
      selected_ids: selected,
    };

    const body = selectAll
      ? {
          ...baseBody,
          filter_flag: true,
          selected_ids: [],
          filters: generatedFilters,
        }
      : baseBody;

    dispatch(
      deleteManageUnPublishedPatentData(body, { onSuccess: handleSuccess }),
    );
  };

  const manageButtonSection = (
    <>
      <Tooltip title="Deselect All">
        <Button
          danger
          onClick={() => {
            props?.setSelectAll(false);
            props?.setSelected([]);
            props?.setSelectedRows([]);
          }}
          disabled={!selected.length || !tableData.length}
          icon={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <MinusCircleOutlined style={{ margin: 0, fontSize: 16 }} />
            </div>
          }
        />
      </Tooltip>
      <Badge
        overflowCount={9999}
        count={selectAll ? "ALL" : selected.length}
        style={{ marginTop: "5px" }}
      >
        <Button
          onClick={handleDelete}
          disabled={!selected.length || !tableData.length}
          icon={<DeleteOutlined />}
          danger
        >
          Delete
        </Button>
      </Badge>
      <Button type="default" onClick={() => setOpenBulkUpload(true)}>
        Bulk Upload
      </Button>
      <Button
        type="primary"
        onClick={() => setOpenAddForm(true)}
        icon={<PlusOutlined />}
      >
        Add Patent
      </Button>
    </>
  );

  const singleAnalytic = (analytic) => (
    <Card
      loading={stats?.loading ?? false}
      key={analytic?.label}
      size="small"
      style={{ textAlign: "center", borderColor: "#e7e7e7" }}
    >
      <Title level={5}>{analytic?.label ?? ""}</Title>
      <Text>{analytic?.value ?? ""}</Text>
    </Card>
  );

  const analyticsSection = stats?.data?.length
    ? stats.data.map((item) => (
        <Col
          span={4}
          key={item.key}
          style={{ padding: "10px", minWidth: "150px" }}
        >
          {singleAnalytic(item)}
        </Col>
      ))
    : null;

  const propsForBulkUpload = {
    open: openBulkUpload,
    setOpen: setOpenBulkUpload,
    getUnPublishedPatentData,
  };

  const propsForAddPatent = {
    open: openAddForm,
    setOpen: setOpenAddForm,
    type: "ADD",
    getUnPublishedPatentData,
  };

  const propsForEditPatent = {
    open: openEditForm,
    setOpen: setOpenEditForm,
    type: "EDIT",
    getUnPublishedPatentData,
    selectedDataForEdit,
  };

  return (
    <>
      {openBulkUpload && <BulkUpload {...propsForBulkUpload} />}
      {openAddForm && <AddEditPatent {...propsForAddPatent} />}
      {openEditForm && <AddEditPatent {...propsForEditPatent} />}
      {openCustomReport && (
        <CreateReportContainer
          open={openCustomReport}
          setOpen={setOpenCustomReport}
          type="MANAGE"
          selected={selected}
          selectAll={selectAll}
          generateFilterObject={generateFilterObject}
        />
      )}
      <Row>
        <Col span={12}>{header}</Col>
        <Col
          span={12}
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 13,
          }}
        >
          {manageButtonSection}
        </Col>
      </Row>
      <Row
        gutter={[16, 16]}
        style={{
          width: "100%",
          flexFlow: "row",
          overflowX: "auto",
          padding: "10px 0",
        }}
      >
        {analyticsSection}
      </Row>
      <Row>
        <Col span={24}>
          <PrimaryFilters {...props} />
        </Col>
      </Row>
    </>
  );
};

export default HeaderSection;
